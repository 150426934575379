"use strict";

import Swiper from "swiper/js/swiper.min.js";

let pSwiper;
const breakpoint = 960;

function productsSwiper() {
  if (window.innerWidth >= breakpoint && !pSwiper) {
    pSwiper = new Swiper(".products-swiper-container", {
      slidesPerView: 3,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  } else if (window.innerWidth < breakpoint && pSwiper) {
    pSwiper.destroy(true, true);
    pSwiper = null;
  }
}

// 初期化時と画面リサイズ時にSwiperの状態を制御
window.addEventListener("load", productsSwiper);
window.addEventListener("resize", productsSwiper);
